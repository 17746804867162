@use '@angular/material' as mat;

// Include non-theme styles for core.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$config: mat.m2-define-typography-config();

// Define a theme.
$app-primary: mat.m2-define-palette(mat.$m2-cyan-palette);
$app-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.m2-define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn )));

// Include theme styles for core and each component used in your app.
@include mat.all-component-themes($app-theme);

// Custom CSS starts here
body {
  font-family: mat.m2-font-family($config);
  margin: 0;
}

.app-content {
  flex: 1 0 auto;
  min-height: 470px;
  padding: 10px;

  .mat-mdc-card {
    margin: 0 auto;
  }

  .mat-mdc-card-actions {
    width: 70%;
    padding: 0 0 20px 80px !important;

    button {
      margin-right: 30px !important;
      text-transform: uppercase;
    }
  }
}

[mat-dialog-title] {
  font-family: mat.m2-font-family($config);
  font-weight: 400 !important;
}

.mat-mdc-table {
  .mat-mdc-cell:focus {
    outline: none;
  }

  .mat-mdc-row {
    cursor: pointer;
  }

  .mat-mdc-row:hover {
    background: #f6f6f6;
  }

  .actions-header-cell,
  .actions-cell {
    text-align: right;

    [md-icon-button] {
      color: #444444;
    }
  }
}

.mat-mdc-dialog-title {
  display: flex !important;

  span {
    font-size: 20px;
    font-weight: 400;
  }
}

.snackbar-error {
  background-color: #bd362f;
  color: #fff;
  box-shadow: 0 0 12px #000;

  .mat-mdc-snack-bar-action {
    color: #fff !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-input-suffix {
  cursor: default;
}

/***********Generic Classes*******/
.center {
  text-align: center;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.grid-width-100 {
  width: 100%;
  display: block;
}

.width-50 {
  width: 50%;
}

.margin-15 {
  margin: 15px;
}

.margin-right-15 {
  margin: 15px;
}

.spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

// remove default grey background from the inputs
.mdc-text-field--filled {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background: #ffffff;
}

mat-button-toggle-group {
  font-size: 14px;
}

// reduce mat-icon size globally
mat-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.mat-mdc-card {
  box-shadow: none !important;
}

/* Add some padding to the mat-dialog container */
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

.form-field-custom-label {
  .mdc-floating-label {
    display: flex;
  }
}

/* CSS to override default mat-select smaller width */
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-size: 13px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-wrap-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.start-align {
  justify-content: flex-start;
}

.flex-50 {
  flex: 1 1 50%;
}

.gap-10 {
  gap: 10px;
}

.gap-30 {
  gap: 30px;
}

.mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fffff !important;
}